import React, { useRef } from 'react'

import { car_5 } from '../Cars/CarsImages/index'
import { Close, ClaimedResultBackground } from '../../common/Icons'
import { CarNameRectangle } from '../Cars/Icons'
import { ActionButton } from '../../ActionButton/ActionButton'
import { DAILY_BONUSES_ACTION_NAMES } from '../../../const'

export const ClaimedResult = ({ pointsNumber, day, onClose }) => {
  const claimResultRef = useRef(null)

  const close = () => {
    navigate('/')
  }

  const handleBackgroundClick = event => {
    if (claimResultRef.current && !claimResultRef.current.contains(event.target)) {
      onClose()
    }
  }

  return (
    <>
      <div className="blur-background-overlay" onClick={handleBackgroundClick}></div>
          <div className="daily-bonuses-claimed-background-container" ref={claimResultRef}>
            <div className="daily-bonuses-claimed-background">
              <ClaimedResultBackground color={'black'} />
            </div>
            <div className="daily-bonuses-claimed-text">
            { day % 7 === 0
             ? `Congrats! You claimed 3 energy for a login bonus`
             : `Congrats! You claimed ${pointsNumber} pts for a login bonus`
            }
            </div>
            <div className="close-button" onClick={onClose}>
              <Close />
            </div>
          </div>
    </>
  )
}
