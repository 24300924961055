import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../Elements/Modal/Modal';
import Tooltip from '../../Elements/Tooltip/Tooltip';
import './Races.css';
import { useNavigate } from 'react-router-dom';
import { 
    fetchCar, 
    tooltipTextColor, 
    fetchStartGame, 
    checkCollisionWithPaths, 
    fetchEndGame, 
    pauseImg, 
    timerImg, 
    wheel,
    drawCar, 
    drawSegment,
    drawCarAtPosition,
    getSegmentParams,
    shuffleArray,
    fetchCarData,
    } from "./helpers";
import { useTooltips } from './useTooltips';
import { usePreloadImages } from './usePreloadImages';
import { useCarAngleHandler } from './useCarAngleHandler';
import { Timer, Score } from './TimerAndScore';

const RacingGame = () => {
  const navigate = useNavigate();
  const { tooltips, addTooltip } = useTooltips();
  const {
    carImg,
    startImgLeft,
    startImgRight,
    finishImgLeft,
    finishImgRight,
    leftSegments,
    rightSegments,
    isImagesLoaded,
    adidasLeftLight,
    adidasRightLight,
    clipLeftLight,
    clipRightLight,
    preloadImages,
  } = usePreloadImages();
  const { handleCanvasClick, carAngleRef } = useCarAngleHandler();
  const canvasRef = useRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);
  const [startTime, setStartTime] = useState(null);
  const [roadSegments, setRoadSegments] = useState([]);
  const [copyRoadSegments, setCopyRoadSegments] = useState([]);
  const requestRef = useRef(null);
  const currentCarAngle = useRef(0);
  const defaultTime = useRef(30);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ptsScore, setPtsScore] = useState(0);
  const scoreMultiplier = useRef(0);
  const amountAdidas = useRef(0);
  const amountDefault = useRef(0);
  const chooseStart = useRef(0);
  const isPaused = useRef(false);
  const isEnding = useRef(false);
  const updatedRemaining = useRef(30);
  const carImgSrc = useRef(null);
  const segmentHeight = useRef(null);
  const roadSegmentsIsSetted = useRef(false);
  const startTimeRef = useRef(null);
  const [currentCarData, setCurrentCarData] = useState(null);
  const [startTooltip, setStartTooltip] = useState(false);
  const [currentRaceId, setCurrentRaceId] = useState(null);

  useEffect(() => {
    fetchCar().then((result) => carImgSrc.current = result).then(() => preloadImages(carImgSrc.current));

    fetchCarData().then(result => setCurrentCarData(result))
  }, []); 

  useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  setStartTooltip(false);

  if (isImagesLoaded && carImg) {
    const carWidth = canvas.width * 0.24;
    const carHeight = (carImg.height / carImg.width) * carWidth;

    canvas.carWidth = carWidth;
    canvas.carHeight = carHeight;

    const carX = (canvas.width / 2) - (canvas.carWidth / 2);
    const carY = (canvas.height / 2) - (canvas.carHeight / 2) + (canvas.height * 15 / 100);

    initializeSegments();
    ctx.drawImage(carImg, carX, carY, canvas.carWidth, canvas.carHeight);

    setStartTooltip(true); 
  }
}, [isImagesLoaded]);

  const initializeSegments = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const canvasWidth = canvas.width;
    const imgWidth = 430;
    const imgHeight = 508;
    const ratio = imgWidth / imgHeight;
    segmentHeight.current = Math.round((canvasWidth / ratio));
    const segment1Y = canvas.height - segmentHeight.current;
    chooseStart.current = Math.floor(Math.random() * 2);
    let imgYHeight

    if(chooseStart.current === 1) {
        ctx.drawImage(startImgLeft, 0, segment1Y, canvas.width, segmentHeight.current);
        imgYHeight =  leftSegments[Math.floor(Math.random() * 3)];
    } else {
        ctx.drawImage(startImgRight, 0, segment1Y, canvas.width, segmentHeight.current);
        imgYHeight = rightSegments[Math.floor(Math.random() * 3)];
    }
    ctx.drawImage(imgYHeight.element, 0, segment1Y - segmentHeight.current, canvas.width, segmentHeight.current);

     const createSegment = (y, idElement, img, side, type, id) => ({
            y,
            idElement,
            img,
            side,
            type,
            hasCollided: false,
            id,
            isColorChanged: false
        });

        const segments = [];
        let initialY = segment1Y;

        segments.push(createSegment(initialY, 0, chooseStart.current === 1 ? startImgLeft : startImgRight, chooseStart.current === 1 ? 'right' : 'left', "start", 100));
        initialY -= segmentHeight.current;

        segments.push(createSegment(initialY, 1, imgYHeight.element, chooseStart.current === 1 ? 'left' : 'right', imgYHeight.type, imgYHeight.id));
        initialY -= segmentHeight.current;

        if(segments[1].type === "adidas") amountAdidas.current ++;
        if(segments[1].type === "default") amountDefault.current ++;

        const defaultSegments = new Array(10 - amountDefault.current).fill().map(() => 'default');
        const adidasSegments = new Array(10 - amountAdidas.current).fill().map(() => 'adidas');
        const clipSegments = new Array(70 - 20 + amountDefault.current + amountAdidas.current).fill().map(() => 'clip');

        let allSegmentsShuffle = [...defaultSegments, ...adidasSegments, ...clipSegments];
        allSegmentsShuffle = shuffleArray(allSegmentsShuffle);


        for (let i = 2; i < 72; i++) {
            const side = segments[i-1].side  === 'left' ? 'right' : 'left';
            const availableSegments = side === 'left' ? leftSegments : rightSegments;
            const typeElement = allSegmentsShuffle[i-2];
            const randomSegment = availableSegments.find((el) => el.type === typeElement);

            if(randomSegment.type === "default") amountDefault.current ++;
            if(randomSegment.type === "adidas") amountAdidas.current ++;

            const img = randomSegment.element;
            const type = randomSegment.type;
            const id = randomSegment.id;
            const idElement = i;

            segments.push(createSegment(initialY, idElement, img, side, type, id));
            initialY -= segmentHeight.current;
        }


        for (let i = 72; i < 120; i++) {
            const side = segments[i-1].side  === 'left' ? 'right' : 'left';
            const availableSegments = side === 'left' ? leftSegments : rightSegments;
            let randomSegment = availableSegments.find((el) => el.type === "clip");

            const img = randomSegment.element;
            const type = randomSegment.type;
            const id = randomSegment.id;
            const idElement = i;

            segments.push(createSegment(initialY, idElement, img, side, type, id));
            initialY -= segmentHeight.current;
        }

        setRoadSegments(segments);
        setCopyRoadSegments(segments);
        roadSegmentsIsSetted.current = true;
        const gifElement = document.getElementById('rotating-gif');
        if (gifElement) {
          gifElement.style.display = 'none';
        }
  }


  const startGame = async () => {
    const result = await fetchStartGame();
    setCurrentRaceId(result?.raceId ?? null);

    setStartTime(Date.now());
    setStartTooltip(false);
    startTimeRef.current = Date.now(); 
    setIsRunning(true);
    requestRef.current = requestAnimationFrame(updateGame);
  };

  useEffect(() => {
    if (Math.floor(updatedRemaining.current) > Math.floor(remainingTime)) {
      setPtsScore((prevScore) => prevScore + 1);
    }
    updatedRemaining.current = remainingTime;
    
    if(remainingTime === 0) {
      fetchEndGame({ raceId: currentRaceId, score: ptsScore });
      setPtsScore(prevScore => Math.round(prevScore * currentCarData.coefficient ?? 1) );
    }
 }, [remainingTime]);

  const updateGame = () => {
    if (!isRunning) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const now = Date.now();
    const elapsedTime = (now - startTime) / 1000;
    const updatedRemainingTime = defaultTime.current - elapsedTime;
    if (updatedRemainingTime <= 0) {    
      setIsRunning(false);
      setRemainingTime(0);
      cancelAnimationFrame(requestRef.current);
      const visibleSegments = roadSegments.filter((segment) => {
        return segment.y >= -canvas.height / 2 && segment.y <= canvas.height;
      });
      resetCarPosition(ctx, canvas, visibleSegments);
      isEnding.current = true;
      return;
    }
    setRemainingTime(updatedRemainingTime); 
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const timeDiff = now - startTimeRef.current;
    const distancePerSecond = 750;

    const deltaY = (distancePerSecond / 1000) * timeDiff;
    startTimeRef.current = now;  

    const newRoadSegments = roadSegments.map((segment, index) => {
      segment.y += deltaY;
      if (segment.y < canvas.height) {
         ctx.drawImage(segment.img, 0, segment.y, canvas.width, segmentHeight.current);
      }
      const canvasHeight = canvas.height;
      const canvasWidth = canvas.width;

     if (segment.id !== 32 || segment.id !== 10 || segment.id !== 100) {
      const { xPercentage, yPercentage, heightPercentage, widthPercentage } = getSegmentParams(segment.id, canvasWidth, canvasHeight);
      const square = drawSegment(ctx, canvasWidth, canvasHeight, segment.y, xPercentage, yPercentage, heightPercentage, widthPercentage);

      if (checkCollisionWithPaths([square], ctx, canvas, carAngleRef.current) && !segment.hasCollided) {
      segment.hasCollided = true;
      if (segment.id === 0 || segment.id === 2) {
        amountAdidas.current += 1;
        addTime();
      } else if (segment.id === 1 || segment.id === 3) {
        for (let i = index ; i >= 0; i--) {
         if (copyRoadSegments[i].type === "clip" && i != index) {
           if (!copyRoadSegments[i].hasCollided) scoreMultiplier.current = 0;
           break;
          }
        }
        addScore();
      }
     }
    }

    if (segment.hasCollided && !segment.isColorChanged) {
     segment.isColorChanged = true;
     switch (segment.id) {
      case 0: 
          segment.img = adidasLeftLight;
          break;
      case 2: 
           segment.img = adidasRightLight;
           break;
      case 1: 
           segment.img = clipLeftLight;
           break;
      case 3: 
           segment.img = clipRightLight;
           break;
     }
    }

      return segment;
    });

    setRoadSegments(newRoadSegments);
    setCopyRoadSegments(newRoadSegments);

    const angleDiff = carAngleRef.current - currentCarAngle.current;
    if (Math.abs(angleDiff) > 1) {
        currentCarAngle.current = currentCarAngle.current + angleDiff * 0.12;
    }
    drawCar(ctx, canvas, carImg, currentCarAngle.current);
    requestRef.current = requestAnimationFrame(updateGame);
  };

  const resetCarPosition = (ctx, canvas, segments) => {
    const carX = (canvas.width / 2) - (canvas.carWidth / 2);
    let carY = (canvas.height / 2) - (canvas.carHeight / 2) + (canvas.height * 15 / 100)
    const carSpeed = 3;

    let differenceA = Math.abs(segments[0].y - canvas.height);
    let differenceB = Math.abs(segments[1].y - canvas.height);
    let segment = differenceA < differenceB ? segments[0] : segments[1];

    let finish = segment.side === "left" ? finishImgLeft : finishImgRight;
    let finishY = segment.y - segmentHeight.current;
    let segmentY = segment.y;

    carAngleRef.current = 0;

    const animateCar = () => {
     ctx.clearRect(0, 0, canvas.width, canvas.height);
     drawCarAtPosition(ctx, segment.img, segmentY, canvas.width, segmentHeight.current, finish, finishY);
    /* const angleDiff = carAngleRef.current - currentCarAngle.current;

    if (Math.abs(angleDiff) > 1) {
        currentCarAngle.current = currentCarAngle.current + angleDiff * 0.1;
        drawCar(ctx, canvas, carImg, currentCarAngle.current);
    } else {
        ctx.drawImage(carImg, carX, carY, canvas.carWidth, canvas.carHeight);
        carY -= carSpeed;
    }*/

    ctx.save();
    ctx.translate(carX + canvas.carWidth / 2, carY + canvas.carHeight / 2);
    ctx.rotate((currentCarAngle.current * Math.PI) / 180);
    ctx.drawImage(carImg, -canvas.carWidth / 2, -canvas.carHeight / 2, canvas.carWidth, canvas.carHeight);
    ctx.restore();

    carY -= carSpeed;

    finishY =  finishY > -1 && (finishY + segmentHeight.current) >= segmentHeight.current ? 0 : finishY + carSpeed * 3;
    segmentY = segmentY >= finishY + segmentHeight.current ? segmentHeight.current : segmentY + carSpeed * 3;

    if (carY <= -canvas.carHeight) {
      setIsModalOpen(true);
      cancelAnimationFrame(animateCar);
      return;
    }

    requestAnimationFrame(animateCar);
  };

  animateCar();
};

  useEffect(() => {
    if (isRunning) {
      requestRef.current = requestAnimationFrame(updateGame);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [isRunning]);


  const handleClick = async (event) => {
    const canvas = canvasRef.current;

    if (isRunning && !isEnding.current) {
      handleCanvasClick(event, canvas);
    } else if(!isRunning && isImagesLoaded && !isEnding.current) {
      await startGame();
    } else if (isEnding.current) {
      canvas.removeEventListener('click', handleClick);
    }
  };

  useEffect(() => {
  const canvas = canvasRef.current;
  canvas.addEventListener('click', handleClick);

  return () => {
    canvas.removeEventListener('click', handleClick);
  };
}, [isRunning, isImagesLoaded, isEnding.current]);


    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                pauseGame();
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);


 const pauseGame = () => {
    isPaused.current = true;
    setIsModalOpen(true);
    setIsRunning(false);
  };

  const resumeGame = () => {
    setStartTooltip(false);
    isPaused.current = false;
    setIsModalOpen(false);
    setStartTime(Date.now() - (defaultTime.current - remainingTime) * 1000);
    startTimeRef.current = Date.now();
    setIsRunning(true);
    requestRef.current = requestAnimationFrame(updateGame);
  }

  const addTime = () => {
    defaultTime.current += 3;
    addTooltip(Date.now(), '+3 sec', '#9AF73D', '4%', '120px', '36px');
 };

   const addScore = () => {
    if(scoreMultiplier.current < 10) {
      scoreMultiplier.current +=1; 
    }
    setPtsScore(prev => (prev + 5 * scoreMultiplier.current));
    const color = tooltipTextColor(scoreMultiplier.current);
    addTooltip(Date.now(), '+5 pts', color, '110px', '130px', '36px');
    if(scoreMultiplier.current > 1) addTooltip(Date.now()+1,`X${scoreMultiplier.current}`, color, '180px', '180px', '36px');
 };

 const exitGame = () => {
    setIsModalOpen(false);
    setIsRunning(false);
    navigate('/');
  }

  return (
    <div className='main-game'>
     <canvas ref={canvasRef} height={window.innerHeight} width={window.innerWidth}></canvas>
       {!isImagesLoaded && !roadSegmentsIsSetted.current && (
        <div id="rotating-gif" className="gif-container">
          <div className='loading'> Loading... </div>
          <img src={wheel} alt="Loading GIF" />
        </div>
      )}
     {
        isImagesLoaded ? (
        <>
        <div className='panel-game' >
        <div className='panel-body' >
          <div className="left-group">
             <img src={timerImg} alt="SVG Image" className='timer' />
             <Timer remainingTime={remainingTime.toFixed(0)} />
          </div>
          <div className="pause-group"><img src={pauseImg} alt="SVG Image" className='pause' onClick={pauseGame} /></div>
          <Score ptsScore={ptsScore} />
        </div>
       </div>

        {
        startTooltip && (
        <div className="tooltip-tap">Tap to start</div>
        )
       }
        </>): (<></>)
     }
     <Modal isOpen={isModalOpen}>
        {isPaused.current ? (
        <>
        <div className='modal-top'>Pause</div>
        <div className="modal-group">
            <div className='resume' onClick={resumeGame}>Resume</div>
            <div className='exit' onClick={exitGame}>Exit</div>
        </div>
        </>
        ) : 
        <>
        <div className='modal-top'>The race is over</div>
         <div className="modal-group">
            <div className='resumeScore'>You got { ptsScore} pts</div>
            <div className='exit' onClick={exitGame}>Exit</div>
        </div>
        </>
        }
      </Modal>
       <Tooltip tooltips={tooltips} />
    </div>
  );
};

export default RacingGame;
