import React from 'react'
import { useNavigate } from 'react-router-dom'

import capitalized from '../../../utils/capitalized'
import { ActionButton } from '../../ActionButton/ActionButton'
import { Light } from '../../common/Icons'
import {
  QUESTS_ACTION_BUTTON_BACKGROUND_COLOR,
  QUESTS_ACTION_BUTTON_TEXT_COLOR,
  QUESTS_ACTION_NAMES,
  QUEST_REWARD_TYPE,
  TG_CHANNELS,
  QUESTS_SUBSCRIPTION_NAMES,
} from '../../../const'

import './Quests.css'
import { useTelegram } from '../../../hooks/useTelegram'
import {  Key } from '../Cars/Icons'

export const QuestItem = ({ quest, send, typeUpdate, claim }) => {
  const navigate = useNavigate()

  if (!quest) return null
  if (!send || !typeUpdate || !claim) return null

  const { id, name, reward, rewardType, questType, isClaimed, userProgress, targetValue, isCompleted } = quest

  const isClaimAction = questType.toLowerCase() === QUESTS_ACTION_NAMES.CLAIM
  const isInviteAction = questType.toLowerCase() === QUESTS_ACTION_NAMES.INVITE
  const isStartAction = questType.toLowerCase() === QUESTS_ACTION_NAMES.START
  const isActivityForKey = questType.toLowerCase() === QUESTS_ACTION_NAMES.RACE
  const isBuy = questType.toLowerCase() === QUESTS_ACTION_NAMES.BUY


  const { user } = useTelegram()

  const navigateToFriendsPage = () => {
    navigate('/friends')
  }

  const handleActionClick = () => {
    if (isClaimed) return

    if (isActivityForKey) {
      navigate('/drift')
    }

    if (isBuy) {
      navigate('/cars')
    }

    if (!isClaimed && isCompleted) {
      claim(id)
    }

    if (isInviteAction) {
      navigateToFriendsPage()
    }

    if (isStartAction && name === QUESTS_SUBSCRIPTION_NAMES.BLOCKCHAIN_SPORTS_CHANNEL && !isCompleted) {
      window.Telegram.WebApp.openTelegramLink(`${TG_CHANNELS.BLOCKCHAIN_SPORTS_CHANNEL}`)
      typeUpdate({ idClaim: id, targetValue })
    }

    if (isStartAction && name === QUESTS_SUBSCRIPTION_NAMES.DRIFTERZ_TELEGRAM && !isCompleted) {
      send({
        tgChannel: TG_CHANNELS.DRIFTERZ_CHAT,
        id: user.id,
        idClaim: id,
        name: 'DRIFTREZ',
        targetValue,
        text: 'Subscribe to DRIFTREZ channel',
      })
    }
  }

  const getActionName = () => {
    return isClaimed
      ? capitalized(QUESTS_ACTION_NAMES.CLAIMED)
      : capitalized(isCompleted ? 'claim' : questType)
  }

  const getActionBackground = () => {
    if ((isClaimAction && !isCompleted) || isClaimed) {
      return QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.GREY
    }

    if (isStartAction) {
      QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.BLACK
    }

    if (isActivityForKey && isCompleted) {
      return QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.GREY
    }

    if (isActivityForKey && !isCompleted) {
      return QUESTS_ACTION_BUTTON_BACKGROUND_COLOR.YELLOW
    }

    return null
  }

  const getCustomTextColor = () => {
    if (isClaimAction && !isCompleted) {
      return QUESTS_ACTION_BUTTON_TEXT_COLOR.GREY
    }

    return null
  }

  const getSecondaryText = () => {
    if (isStartAction) {
      return 'Subscribe to Telegram channel'
    }
    if (isInviteAction) {
      return `${userProgress} friends invited`
    }
    if (isClaimAction) {
      return `${userProgress}/${targetValue} pts`
    }
    if (isActivityForKey) {
      return `${userProgress}/${targetValue} races`
    }
    if (isBuy) {
      return `${userProgress}/${targetValue} cars`
    }
  }

  const showEnergy = rewardType === QUEST_REWARD_TYPE.ENERGY
  const showPoints = rewardType === QUEST_REWARD_TYPE.POINTS || rewardType === QUEST_REWARD_TYPE.CAR
  const showKey = rewardType === QUEST_REWARD_TYPE.KEY

  return (
    <div className={`quest-item ${isClaimed ? 'claimed' : ''}`}>
      <div className="quest-info">
        <span className="quest-description">
          <span className="main-quest-description">{name}</span>
          <span className="second-quest-description">{getSecondaryText()}</span>
        </span>
        {showEnergy && (
          <span className="quest-energy-container">
            <span className="light-icon">
              <Light />
            </span>
            <span className="quest-energy">{reward}</span>
          </span>
        )}
        {showPoints && <span className="quest-points">{reward} pts</span>}
        {showKey &&  
                  <span className="quest-energy-container">
                  <span className="light-icon">
                  <Key size={24} />
                  </span>
                  <span className="quest-energy">{reward}</span>
                </span>
}
        <ActionButton
          actionCallback={handleActionClick}
          actionName={getActionName()}
          background={getActionBackground()}
          customTextColor={getCustomTextColor()}
        />
      </div>
    </div>
  )
}
