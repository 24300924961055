import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Light,
  PointsHintBackground,
  VectorsGroupTop,
  VectorsGroupBottom,
  RectangleBottom,
  DailyBonusesFrame,
  DailyBonusesActive,
  DrifterzLogo,
  EnergyHintBackground,
  Drift,
  FadedDrift,
  OnlyMobileExperienceBackground,
} from './Icons'
import config from '../../../config'
import { Spinner } from '../../Spinner/Spinner'
import { useTelegram } from '../../../hooks/useTelegram'

import './Main.css'
import axios from 'axios'

const MainPage = ({ setAppReadyCallback }) => {
  const navigate = useNavigate()
  const { user, initData } = useTelegram()

  const [isMobile, setIsMobile] = useState(false)
  const [showEnergyScore, setShowEnergyScore] = useState(false)
  const [showPointsHint, setShowPointsHint] = useState(false)
  const [mainPageData, setMainPageData] = useState(null)
  const [isClaimed, setIsClaimed] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent

    if (/android|iphone/i.test(userAgent)) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    const fetchMainPageData = async () => {
      const {data, status} = await axios(`${config.serverBaseUrl}/create-or-login`, {
        method: 'POST',
        data: {
           initData, tgId: user.id 
        }
      })
      console.log({data,status})
      
      if (data.accessToken) {
        localStorage.setItem('accessToken', data.accessToken)
      }
      console.log(data.accessToken)
      setMainPageData({
        points: data.user.points,
        energy: data.user.energy,
      })
      setAppReadyCallback(true)
    }

    fetchMainPageData()
  }, [])

  useEffect(() => {
    const fetchDailyBonuses = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/bonuses/progress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()
        setIsClaimed(result.claimedDays == result.loginStreak)
      } catch (error) {
        console.error('Error fetching daily bonuses data:', error)
      }
    }
    fetchDailyBonuses()
  }, [])

  const togglePointsHint = () => {
    setShowPointsHint(prev => !prev)
    if (!showPointsHint) {
      setShowEnergyScore(false)
    }
  }

  const toggleEnergyHint = () => {
    setShowEnergyScore(prev => !prev)
    if (!showEnergyScore) {
      setShowPointsHint(false)
    }
  }

  const startDrift = () => {
    navigate('/drift')
  }

  const handleDailyBonusesClick = () => {
    navigate('/dailyBonuses')
  }

  if (!mainPageData) {
    return <Spinner />
  }

  const Energy = () => (
    <div className="energy" onClick={toggleEnergyHint}>
      <div className="light-icon">
        <Light />
      </div>
      <div className="energy-score">{`${mainPageData.energy} / 10`}</div>
    </div>
  )

  const EnergyHint = () => (
    <div className="energy-hint-container">
      <div className="rectangle-background">
        <EnergyHintBackground />
      </div>
      <div className="energy-hint-text">Invite friends and complete quests to get more energy</div>
    </div>
  )

  const Points = () => (
    <div className="points-container" onClick={togglePointsHint}>
      <div className="rectangle-background-points"></div>
      <div className="points-text">{mainPageData.points} pts</div>
    </div>
  )

  const PointsHint = () => (
    <div className="points-hint-container">
      <div className="rectangle-background">
        <PointsHintBackground />
      </div>
      <div className="points-hint-text">
        Take part in races, invite friends and complete quests to get more points
      </div>
    </div>
  )

  const MainLogo = () => (
    <>
      <div className="vectors-group-top">
        <VectorsGroupTop />
      </div>
      <div className="drifterz-logo">
        <DrifterzLogo />
      </div>
    </>
  )

  const DriftSection = () => {
    const noEnergy = mainPageData.energy === 0

    if (noEnergy) {
      return (
        <div className="faded-drift">
          <FadedDrift />
        </div>
      )
    }

    return (
      <div className="drift" onClick={startDrift}>
        <Drift />
      </div>
    )
  }

  const DailyBonuses = () => {
    if (!isClaimed) {
      return (
        <div className="daily-bonuses-active" onClick={handleDailyBonusesClick}>
          <DailyBonusesActive />
        </div>
      )
    }

    return (
      <div className="daily-bonuses" onClick={handleDailyBonusesClick}>
        <DailyBonusesFrame />
      </div>
    )
  }

  const BottomVectors = () => (
    <div className="bottom-vectors-container">
      <div className="rectangle-bottom">
        <RectangleBottom />
      </div>
      <div className="vectors-group-bottom">
        <VectorsGroupBottom />
      </div>
    </div>
  )

  const OnlyMobileExperience = () => (
    <div className="only-mobile-container">
      <OnlyMobileExperienceBackground />
    </div>
  )

  const getContent = () => {
    // if (!isMobile) return <OnlyMobileExperience />

    return (
      <>
        <Energy />
        {showEnergyScore && <EnergyHint />}
        <Points />
        {showPointsHint && <PointsHint />}
        <MainLogo />
        <DriftSection />
        <DailyBonuses />
        <BottomVectors />
      </>
    )
  }

  return <div className={!isMobile ? 'main-container blur-background' : ''}>{getContent()}</div>
}

export default MainPage
