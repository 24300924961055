import React, { useState, useEffect } from 'react'

import config from '../../../config'
import { Title, Check, CarNameRectangle, Locked, Key } from './Icons'
import { Spinner } from '../../Spinner/Spinner'
import { mapCarById } from './helpers'
import './Cars.css'
import './Cars.scss'
import RenderIf from '../../common/RenderIf'
import { useTelegram } from '../../../hooks/useTelegram'
import Modal from '../../Elements/Modal/Modal'

const CarsPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userCars, setUserCars] = useState(null)
  const [currentCar, setCurrentCar] = useState(null)
  const [isPurchaseProcessing, setIsPurchaseProcessing] = useState(false)
  const [lockedCars, setLockedCars] = useState([]) // id of locked
  const [ isSuccessKeyDialog, setSuccessKeyDialog ] = useState(false) 

  const { tg } = useTelegram()

  const fetchCars = async () => {
    const token = localStorage.getItem('accessToken')
    try {
      const response = await fetch(`${config.serverBaseUrl}/cars`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()

      const lockedCars = result.filter(el => !el.isUnlocked).map(el => el.id)

      const carsByCategory = result.reduce((acc, el) => {
        const {id, name, isUnlocked, coefficient, starsPrice, category} = el

        acc[el.category] = [...(acc[el.category] || []), {
          id,
          imgSrc: mapCarById[id], 
          name,
          isUnlocked,
          price: {
            amount: starsPrice,
            currency: 'XTR', 
          },
          category,
          coefficient,
        }]
        return acc
      }, {})

      console.log(carsByCategory)

      setUserCars(carsByCategory)
      setLockedCars(lockedCars)
    } catch (error) {
      console.error('Error fetching leaders data:', error)
    }
  }

  useEffect(() => {
    fetchCars()
  }, [])


  useEffect(() => {
    const fetchCurrentCar = async () => {
      const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/cars/current`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const result = await response.json()

        setCurrentCar(result)
      } catch (error) {
        console.error('Error fetching leaders data:', error)
      }
    }

    fetchCurrentCar()
  }, [])

  const handleCarClick = car => {
    if (!lockedCars.includes(car.id)) {
      setCurrentCar(car)
      const setNewCar = async () => {
        const token = localStorage.getItem('accessToken')
        try {
          await fetch(`${config.serverBaseUrl}/cars/change-current`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ id: car.id }),
          })
        } catch (error) {
          console.error('Error fetching leaders data:', error)
        }
      }
      setNewCar()
    }
  }

  const handleOnPurchaseCarCLick = async (car) => {
    setIsPurchaseProcessing(true);

    const token = localStorage.getItem('accessToken')
      try {
        const response = await fetch(`${config.serverBaseUrl}/cars/${car.id}/generate-invoice`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })

        const result = await response.json()

        tg.openInvoice(result.invoiceLink, (status) => {
          if (status === 'paid') {
            // TODO: open the success popup here

            if (car.category === 'COMMON') {
              setSuccessKeyDialog(true)
            }

            setTimeout(async () => {
              await fetchCars()
            }, 500)
          }
        })

        console.log('result', result)
      } catch (error) {
        console.error('Error fetching leaders data:', error)
      } finally {
        setIsPurchaseProcessing(false);
      }
  }


  const getContent = () => {
    if (!userCars || !currentCar) {
      return <Spinner />
    }

    return (
      <div className="cars-category-list" >
        {Object.entries(userCars)?.map(([category, cars], i) => {
          return  (
            <div key={category}>
              <span className={`purchase-coefficient ${category.toLowerCase()}`} style={{marginBottom: "20px"}}>{ category }</span>

              <div className='cars-grid'>
                {cars.map((car, index) => {
                  const isLocked = lockedCars.includes(car.id)
                  const isSelected = currentCar.id === car.id
                  const isToPurchase = car.price.amount !== 0 && isLocked;

                  return (
                    <div
                      key={car.id}
                      className={`car-square ${isLocked ? 'locked' : ''} car-square-category-${car.category.toLowerCase()}`}
                      onClick={() => handleCarClick(car)}
                    >
                      {isSelected && !isLocked && (
                        <div className="check-mark">
                          <Check />
                        </div>
                      )}
        
                      {isLocked && car.category === 'COMMON' && (
                        <div className='check-mark-text'>
                          +70
                          <Key/>
                        </div>
                      )}
        
        
                      <img src={car.imgSrc} alt={`Car ${index + 1}`} className="car-image" />
                      <div className="car-name-container">
                        <div className="car-name-rectangle">
                          <CarNameRectangle />
                          <div style={{top: car.name.length > 15 ? 4 : 10}} className={`car-name ${isSelected && 'selected-car'}`}>{car.name}</div>
                        </div>
                      </div>
                      <RenderIf isTrue={isToPurchase}>
                        <div className="purchase-overlay">
                          <div className="purchase-block">
                            <div style={{fontSize: 16}} className={`purchase-coefficient ${car.category.toLowerCase()} purchase-category`}>{car.category}</div>
                            <div  className="purchase-name">{car.name}</div>
                            <div className={`purchase-coefficient ${car.category.toLowerCase()}`}>x{car.coefficient} pts</div>
                            {isLocked && car.category === 'COMMON' && (
                              <div className='check-mark-text'>+70<Key size={16}/></div>
                            )}
                          </div>
                          <div className="purchase-block">
                            <button
                              className="purchase-price-button"
                              disabled={isPurchaseProcessing}
                              onClick={() => handleOnPurchaseCarCLick(car)}
                            >
                              {car.price.amount} Stars
                            </button>
                          </div>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={isLocked && !isToPurchase}>
                        <div className="locked-overlay">
                          <div className="locked-icon">
                            <Locked />
                          </div>
                          <div className="locked-text">Locked</div>
                          <div className="locked-description">
                            Invite friends, complete quests and log in every day to unlock
                          </div>
                        </div>
                      </RenderIf>
                    </div>
                  )

                })}
              </div>
            </div>
          )
        })}

        <div className='white-background'>
          <Modal isOpen={isSuccessKeyDialog}>
            <div className='key-success-purchase-dialog'>
              <span>Success</span>
              <span className='keyValue'>+70 <Key size={32}/></span>
              <button className='okBtn' onClick={() => setSuccessKeyDialog(false)}>OK</button>
            </div>
          </Modal>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="title">
        <Title />
      </div>
      {getContent()}
    </>
  )
}

export default CarsPage
