import React, { useState, useEffect, useRef } from 'react'

import config from '../../../config'
import { QuestItem } from './QuestItem'
import { Spinner } from '../../Spinner/Spinner'
import { Title, Close } from '../../common/Icons'
import { ActionButton } from '../../ActionButton/ActionButton'
import { ClaimedResult } from './ClaimedResult'

import './Quests.css'

const QuestsPage = () => {
  const inviteDialogRef = useRef(null)

  const [quests, setQuests] = useState(null)
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [data, setData] = useState({})
  const [isBlurred, setIsBlurred] = useState(false)

  const fetchQuests = async () => {
    setQuests(null)
    const token = localStorage.getItem('accessToken')
    try {
      const response = await fetch(`${config.serverBaseUrl}/quests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()
      setQuests(result)
    } catch (error) {
      console.error('Error fetching quests data:', error)
    }
  }

  const closeInviteModal = () => {
    setInviteDialogOpen(false)
  }

  const handleClickOutside = event => {
    if (inviteDialogRef.current && !inviteDialogRef.current.contains(event.target)) {
      closeInviteModal()
    }
  }

  useEffect(() => {
    fetchQuests()
  }, [])

  useEffect(() => {
    if (inviteDialogOpen) {
      document.addEventListener('touchstart', handleClickOutside)
    } else {
      document.removeEventListener('touchstart', handleClickOutside)
    }

    return () => {
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [inviteDialogOpen])

  const typeUpdate = async data => {
    const token = localStorage.getItem('accessToken')
    try {
      setQuests(null)
      const response = await fetch(`${config.serverBaseUrl}/quests/type-update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data }),
      })
      const result = await response.json()
      setQuests(result)
    } catch (error) {
      console.error('Error during claim:', error)
    }
  }

  const claimAll = async () => {
    const token = localStorage.getItem('accessToken')
    try {
      setQuests(null)
      const response = await fetch(`${config.serverBaseUrl}/quests/claim-all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const result = await response.json()
      setQuests(result)
      setIsBlurred(true)
    } catch (error) {
      console.error('Error during claim all on quests page: ', error)
    }
  }

  const checkSubscription = async data => {
    const token = localStorage.getItem('accessToken')
    try {
      setQuests(null)
      const response = await fetch(`${config.serverBaseUrl}/quests/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data }),
      })
      const result = await response.json()
      setQuests(result)
    } catch (error) {
      console.error('Error during claim:', error)
    }
  }

  const claimElement = async questId => {
    const token = localStorage.getItem('accessToken')
    try {
      setQuests(null)
      const response = await fetch(`${config.serverBaseUrl}/quests/claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ questId }),
      })
      const result = await response.json()
      setQuests(result)
    } catch (error) {
      console.error('Error during claim:', error)
    }
  }

  const handleClaimResultClose = () => {
    setIsBlurred(false)
  }

  const getContent = () => {
    if (!quests) {
      return <Spinner />
    }

    const sortedQuests = Array.isArray(quests) ? [...quests].sort((a, b) => a.isClaimed - b.isClaimed) : []

    const redirectToTgChannel = () => {
      window.Telegram.WebApp.openTelegramLink(`${data.tgChannel}`)
    }

    const checkTgChannel = () => {
      checkSubscription(data)
      closeInviteModal()
    }

    const getDataSubscription = dataVal => {
      setInviteDialogOpen(true)
      setData(dataVal)
    }

    return (
      <>
        <div className="quests-container">
          <div className="subtitle-quests">We'll reward you with prizes after each task completion</div>
        </div>
        <div className="claim-all-action" onClick={claimAll}>
          Claim all
        </div>
        <div className="quests-list">
          {sortedQuests.map((quest, index) => (
            <React.Fragment key={quest.id}>
              <QuestItem
                quest={quest}
                send={getDataSubscription}
                typeUpdate={typeUpdate}
                claim={claimElement}
              />
              {index < quests.length - 1 && <div className="quests-divider"></div>}
            </React.Fragment>
          ))}
        </div>

        {inviteDialogOpen && (
          <div className="main-container">
            <div className={`blur-overlay ${inviteDialogOpen ? 'active' : ''}`}>
              <div className="invite-modal-container">
                <div className="modal-base" ref={inviteDialogRef}>
                  <div className="invite-friends-header-container">
                    <div className="invite-channel-header">{data.text}</div>
                    <div className="close-icon" onClick={closeInviteModal}>
                      <Close />
                    </div>
                  </div>
                  <div className="modal-divider"></div>
                  <div className="invite-actions-container">
                    <ActionButton
                      actionName={'Subscribe'}
                      className="invite-friends-action invite-friends-action-text invite-action-button"
                      actionCallback={redirectToTgChannel}
                    />
                    <ActionButton
                      actionName={'Check subscription'}
                      className="invite-friends-action invite-friends-action-text invite-action-button channel-button"
                      actionCallback={checkTgChannel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {isBlurred ? (
        <ClaimedResult onClose={handleClaimResultClose} />
      ) : (
        <div className="main-container">
          <div className="title-container">
            <div className="rectangle-quests-background">
              <Title />
            </div>
            <div className="title-text">Quests</div>
          </div>
          {getContent()}
        </div>
      )}
    </>
  )
}

export default QuestsPage
