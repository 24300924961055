import { car_1, car_2, car_3, car_5, car_6, car_7, car_8, car_9, car_10, car_11, car_4, car_12, car_13, car_14, car_15 } from './CarsImages'

export const mapCarById = {
  [1]: car_1,
  [2]: car_2,
  [3]: car_3,
  [4]: car_4,
  [5]: car_5,
  [6]: car_6,
  [7]: car_7,
  [8]: car_8,
  [9]: car_9,
  [10]: car_10,
  [11]: car_11,
  [12]: car_12,
  [13]: car_13,
  [14]: car_14,
  [15]: car_15,
}
