export const MENU_ITEMS_COLOR = {
  YELLOW: '#dff73d',
  WHITE: '#ffffff',
}

export const QUESTS_ACTION_BUTTON_BACKGROUND_COLOR = {
  YELLOW: '#dff73d',
  BLACK: '#000000',
  GREY: '#3D3D3F',
}

export const QUESTS_ACTION_BUTTON_TEXT_COLOR = {
  BLACK: '#000000',
  WHITE: '#ffffff',
  YELLOW: '#9AF73D',
  GREY: '#6D6D6D',
}

export const QUESTS_ACTION_NAMES = {
  CLAIM: 'claim',
  CLAIMED: 'claimed',
  INVITE: 'invite',
  START: 'start',
  RACE: "race",
  BUY: "buy"
}

export const FRIENDS_ACTION_NAMES = {
  CLAIM: 'Claim',
  INVITE_FRIENDS: 'Invite friends',
  SEND: 'Send',
  COPY_LINK: 'Copy Link',
}

export const FRIENDS_ACTION_BUTTON_BACKGROUND_COLOR = {
  YELLOW: '#dff73d',
  GREY: '#3D3D3F',
}

export const QUEST_REWARD_TYPE = {
  ENERGY: 'ENERGY',
  POINTS: 'PTS',
  CAR: 'CAR',
  KEY: 'KEY',
}

export const TG_CHANNELS = {
  ATLETA_CHANNEL: 'https://t.me/atleta_network',
  DRIFTERZ_CHAT: 'https://t.me/Drifterz_channel',
  BLOCKCHAIN_SPORTS_CHANNEL: 'https://t.me/bcsports_io',
}

export const QUESTS_SUBSCRIPTION_NAMES = {
  DRIFTERZ_TELEGRAM: 'Subscribe to Drifterz Telegram',
  ATLETA_CHANNEL: 'Subscribe to ATLETA',
  BLOCKCHAIN_SPORTS_CHANNEL: 'Subscribe to Blockchain Sports',
}

export const DAILY_BONUSES_ACTION_NAMES = {
  CLAIM: 'Claim',
  REDIRECT: 'Go to Cars!',
}

export const DAILY_BONUSES_ACTION_BUTTON_BACKGROUND_COLOR = {
  GREY: '#3D3D3F',
}

export const DAILY_BONUSES_ACTION_BUTTON_TEXT_COLOR = {
  GREY: '#6D6D6D',
}
