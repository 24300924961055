import { useLocation } from 'react-router-dom'

import './App.css'

export const BackgroundWrapper = ({ children }) => {
  const location = useLocation()

  const backgroundImage = `url(${require('./assets/background.png')})`

  const backgroundStyle = location.pathname === '/' ? { backgroundImage } : { background: '#3c3c3c' }

  return (
    <>

    <div className="background" style={backgroundStyle}>
    {children}
    </div>
</>
  )
}
