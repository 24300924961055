import PropTypes from 'prop-types';

const RenderIf = ({ isTrue = false, children }) => {
  if (isTrue) {
    return <>{children}</>
  }

  return null;
};

RenderIf.propTypes = {
  isTrue: PropTypes.bool,
};

export default RenderIf;
